.main-training-container {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll !important;
}

.main-training-content {
  display: grid;
  padding-top: 50px;
  padding-left: 50px;
  grid-template-columns: auto 30%;
  height: 80%;
  align-items: center;
}

.main-video-container * {
  margin: 0;
}

.main-video-container {
  margin-right: 2%;
  min-width: 80%;
}

.class-name {
  font-size: 28px;
  color: #5b0390;
  font-weight: 600;
  margin-bottom: 10px;
}

.playlist-name {
  font-size: 20px;
  color: #150442;
  margin-bottom: 20px;
}

.class-info {
  color: #5b5b5b;
  font-size: 15px;
}

/* Progress section */
.playlist-section {
  color: white;
}

.progress-section {
  background: linear-gradient(
    0deg,
    rgba(21, 4, 66, 1) 0%,
    rgb(101, 0, 134) 100%
  );
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.placeholder {
  width: 100%;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.classes-progress-bar {
  background-color: red;
  border-radius: 5px;
  height: 8px;
  width: 100%;
}
progress::-webkit-progress-value {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 5px;
}

progress::-webkit-progress-bar {
  background-color: rgb(155, 155, 155) !important;
  border-radius: 5px;
}

.reset-progress-button {
  display: block;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  margin: 12px auto;
}

.reset-progress-button:hover {
  cursor: pointer;
}

.classes-main-container * {
  color: #150442;
}

.classes-main-container {
  margin-top: 15px;
  border-radius: 10px;
  border: 3px solid #150442;
}

.class-container {
  display: flex;
  border-bottom: 3px solid #150442;
  align-items: center;
  font-size: 1.3rem;
}

.class-container div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickableClass:hover {
  cursor: pointer;
}

.view-image {
  width: 24px;
  margin-right: 10px;
  margin-left: 10px;
}

@media screen and (max-width: 960px) {
  .main-training-content {
    grid-template-columns: auto;
  }
}

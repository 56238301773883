.ps__rail-x {
    background-color: #f1f1f1;
    height: 12px;
}
.ps__rail-y {
    background-color: #f1f1f1;
    width: 12px;
}
.ps__thumb-x {
    background-color: #888;
    border-radius: 10px;
}
.ps__thumb-y {
    background-color: #888;
    border-radius: 10px;
}
.ps__thumb-x:hover,
.ps__thumb-y:hover {
    background-color: #555;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&display=swap");

.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: url(./background-login.png);
  background-repeat: no-repeat;
  background-size: cover; */
}

.main-login-component {
  font-family: "Montserrat", sans-serif;
  display: flex;
  filter: drop-shadow(-4px 7px 3px #bf00ff57);
  border-radius: 35px;
  width: 689px;
  height: 429px;
  background-color: #fdfdfd;
  /* padding: 20px; */
}

@media screen and (max-width: 768px) {
  .main-login-component {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 10px;
  }
}

.login-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 50%;
  min-height: 100%;
}

.main-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-login-form input {
  border: none;
  background-color: #d9d9d9;
  border-radius: 8px;
  height: 30px;
  width: 235px;
  margin: 10px 0;
}

.sign-in {
  /* margin: auto 0; */
  color: #5b0390;
  font-size: 28px;
  font-weight: 500;
}

.login-button {
  width: 167px;
  height: 37px;
  background-color: #5b0390;
  color: white;
  border-radius: 10px;
  border: none;
}
.login-button:hover {
  cursor: pointer;
}

/* Crontatar */

.contract-section {
  background: linear-gradient(
    0deg,
    rgba(21, 4, 66, 1) 0%,
    rgba(190, 0, 255, 1) 100%
  );
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: 70px;
  border-top-left-radius: 70px;
}

@media screen and (max-width: 768px) {
  .contract-section {
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.cantract-section-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: 70px;
  border-top-left-radius: 70px;
  height: 100%;
}

.salute {
  /* margin: auto 0; */
  font-size: 28px;
  font-weight: 500;
  margin: 0;
}

.salute-info {
  font-weight: 300;
  text-align: center;
}

.contract-buttons {
  display: flex;
  flex-direction: column;
}

.contract-buttons button {
  margin: 10px 0;
}

.contract-buttons button:hover {
  cursor: pointer;
}

.contract-button {
  width: 167px;
  height: 37px;
  background-color: #5b0390;
  color: white;
  border-radius: 10px;
  border: 1px solid white;
}

.contract-buttons .test-button {
  width: 167px;
  height: 37px;
  background-color: white;
  color: #5b0390;
  border-radius: 10px;
}

.contract-section img {
  width: 40%;
}

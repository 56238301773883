.ps__rail-x, .ps__rail-y {
    opacity: 1!important;

}


.ps__thumb-x, .ps__thumb-y {
    background-color: #c1c1c1; 
    border-radius: 4px; 
}

.ps__rail-x:hover, .ps__rail-y:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Fundo da barra de rolagem ao passar o mouse */
}